<template>
    <v-row no-gutters>
        <v-col cols="12">
            <BrandBar :accountId="$route.params.accountId" :brandId="$route.params.brandId" :brand="brand" class="mb-6"></BrandBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-brand', params: { accountId: this.$route.params.accountId } }">Brands</router-link>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ brand.name }}</h1>
                <p class="text-caption text-center">Brand overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="brand">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card>
                        <v-toolbar color="teal" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Brand
                        </v-toolbar-title>
                        <template v-if="isDraft">
                            <v-spacer/>
                            <v-btn icon color="white green--text" @click="publish">
                                <font-awesome-icon :icon="['fas', 'cloud-upload-alt']"/>
                                <!-- Publish -->
                            </v-btn>
                        </template>

                        <!-- <v-progress-linear
                            :active="emailDispatchStatus && emailDispatchStatus.status === 'started'"
                            :value="emailDispatchProgress"
                            absolute
                            bottom
                            color="green"
                        ></v-progress-linear> -->
                        </v-toolbar>
                        <v-card-text>

                    <p class="text-overline mb-0">Name</p>
                    <p class="mb-0 pb-0">
                        {{ brand.name }}
                        <v-btn icon color="green" @click="editBrandName">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>

                    <!-- TODO: we should only show ONE primary alias in the the output, and the rest should be in a separate section of alternative aliases that redirect to the primary one  (clients will get 301 Moved Permanently redirecting them to the primary alias when they try to access these alternative aliases at the CDN) so the code below needs to be reworked for this, to show just the primary alias and then if there are any alternative aliases to show a link to view them (maybe in a dialog box) and a count of how many there are next to the link , something like "N old aliases redirect to this one" or "See former alias list (N)" or "Alias redirects (N)" -->
                    <p class="text-overline mb-0 mt-8">Alias</p>
                    <p class="mb-0 pb-0" v-if="!brand.alias">
                        Pending...
                        <!-- <v-btn color="green white--text" @click="editBrandAlias">
                            Create an alias
                        </v-btn> -->
                    </p>
                    <p class="mb-0 pb-0" v-if="brand.alias">
                        {{ brand.alias }}
                        <v-btn icon color="green" :href="linkViewBrandAlias" target="_blank">
                            <font-awesome-icon :icon="['fas', 'external-link-alt']"/>
                        </v-btn>

                        <!-- <router-link :to="{ name: 'account-edit-brand-alias', params: { accountId: this.$route.params.accountId, brandId: this.$route.params.brandId } }" class="ml-4">Manage...</router-link> -->

                        <v-btn icon color="green" :to="{ name: 'account-edit-brand-alias', params: { accountId: this.$route.params.accountId, brandId: this.$route.params.brandId } }">
                            <font-awesome-layers>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                            <font-awesome-icon :icon="['far', 'ellipsis-h']" transform="down-8 right-4 shrink-6"/>
                            </font-awesome-layers>
                        </v-btn>
                    </p>
                    <!-- TODO: remove this section about 'free alias' and 'premium alias' completely , because we have a new brand alias list view now -->
                    <!--
                    <p class="mb-0 pb-0" v-if="!brand.free_alias">
                        <v-btn color="green white--text" @click="editBrandFreeAlias">
                            Create a free alias
                        </v-btn>
                    </p>
                    <p class="mb-0 pb-0" v-if="brand.free_alias">
                        {{ brand.free_alias }} (free)
                        <v-btn icon color="green" :href="linkViewBrandFreeAlias" target="_blank">
                            <font-awesome-icon :icon="['fas', 'external-link-alt']"/>
                        </v-btn>
                        <v-btn icon color="green" @click="editBrandFreeAlias">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>
                    <p class="mb-0 pb-0 mt-4" v-if="!brand.premium_alias">
                        <v-btn color="green white--text" @click="editBrandPremiumAlias">
                            Create a premium alias
                        </v-btn>
                    </p>
                    <p class="mb-0 pb-0 mt-4" v-if="brand.premium_alias">
                        {{ brand.premium_alias }} (premium)
                        <v-btn icon color="green" :href="linkViewBrandPremiumAlias" target="_blank">
                            <font-awesome-icon :icon="['fas', 'external-link-alt']"/>
                        </v-btn>
                        <v-btn icon color="green" @click="editBrandPremiumAlias">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>
                    -->

                    <!-- <p class="text-overline mb-0 mt-8">Comment</p>
                    <p class="mb-0 pb-0">
                        {{ brand.comment }}
                        <v-btn icon color="green" @click="editBrandComment">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p> -->

                    <!-- <p class="text-overline mb-0 mt-8">Themes</p>
                    <p class="text-caption mb-0 mt-0">A theme is a coordinated set of color palettes, images, and text.</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-theme', params: { accountId: this.$route.params.accountId }, query: { brandId: this.$route.params.brandId } }">See all themes</router-link>
                    </p> -->

                    <!-- TODO: logo, screenshots, other images -->
                    <p class="text-overline mb-0 mt-8">Images</p>
                    <p class="text-caption mb-0 mt-0">Logos, screenshots, etc.</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-image', params: { accountId: this.$route.params.accountId }, query: { brand_id: this.$route.params.brandId } }">See all images for this brand</router-link>
                    </p>

                    <p class="text-overline mb-0 mt-8">Colors and Palettes</p>
                    <p class="text-caption mb-0 mt-0">Color definitions and combinations</p>
                    <!-- <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-color', params: { accountId: this.$route.params.accountId }, query: { brandId: this.$route.params.brandId } }">See all colors</router-link>
                    </p> -->
                    <p>
                        <router-link :to="{ name: 'account-search-palette', params: { accountId: this.$route.params.accountId }, query: { brand_id: this.$route.params.brandId } }">See all palettes for this brand</router-link>
                    </p>

                    <!-- <p class="text-overline mb-0 mt-8">Files</p>
                    <p class="text-caption mb-0 mt-0">Used to import and export data and assets.</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-file', params: { accountId: this.$route.params.accountId } }">See all files</router-link>
                    </p> -->

                    <!-- TODO: mailboxes for people to contact the brand (code will be similar to email origins, but for different purpose) -->
                    <!-- <p class="text-overline mb-0 mt-8">Email Contacts</p>
                    <p class="text-caption mb-0 mt-0">Mailboxes customers can use to reach you, such as 'hello@example.com'.</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-email-contact', params: { accountId: this.$route.params.accountId } }">See all email contacts</router-link>
                    </p> -->
                    <!-- TODO: document templates (docx, odt, xlsx, ods, pptx, odp, etc.) -->
                    <!-- <p class="text-overline mb-0 mt-8">Document templates</p>
                    <p class="text-caption mb-0 mt-0">Branded document templates to share with your team.</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-document-template', params: { accountId: this.$route.params.accountId } }">See all email templates</router-link>
                    </p> -->
                    <!-- TODO: website list -->
                    <!-- <p class="text-overline mb-0 mt-8">Websites</p>
                    <p class="text-caption mb-0 mt-0">Websites used to interact with your customers.</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-website', params: { accountId: this.$route.params.accountId } }">See all websites</router-link>
                    </p> -->

                    <p class="text-overline mb-0 mt-8">Unsubscribe</p>
                    <p class="mb-0 pb-0" v-if="brand.attr.unsubscribe_link">
                        Unsubscribe link
                        <v-btn icon color="green" :href="linkBrandUnsubscribe" target="_blank">
                            <font-awesome-icon :icon="['fas', 'external-link-alt']"/>
                        </v-btn>
                        <v-btn icon color="green" @click="editBrandUnsubscribe">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>
                    <p class="mb-0 pb-0" v-if="!brand.attr.unsubscribe_link">
                        Add an unsubscribe link
                        <v-btn icon color="green" @click="editBrandUnsubscribe">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>

                    <p class="text-overline mb-0 mt-8">Status</p>
                    <p class="mb-0 pb-0">
                        <span v-if="typeof isPublished === 'boolean' && isPublished">Published</span>
                        <span v-if="typeof isPublished === 'boolean' && !isPublished">Draft</span>
                    </p>

                    <p class="text-overline mb-0 mt-8">Review</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-review-published', params: { accountId: this.$route.params.accountId }, query: { brand_id: this.$route.params.brandId } }">See all published assets for this brand</router-link>
                    </p>

                    <p class="text-overline mb-0 mt-8">Brand ID</p>
                    <p class="mb-0 pb-0">
                        {{ brand.id }}
                    </p>

                        </v-card-text>
                    </v-card>

                    <!-- TODO: the affected X list should depend on the brand type... instead of hard-coding email contacts -->
                    <!-- <p class="text-overline mb-0 mt-8">Status</p>
                    <p class="mb-0 pb-0">
                        <span v-if="affectedEmailContactList.length > 0">
                            This brand is subscribed by {{ affectedEmailContactList.length }} email contacts.
                            < ! - - TODO: show the affectedEmailContactList - - >
                        </span>
                        <span v-if="affectedEmailContactList.length === 0">
                        This brand is not currently applied to any email contacts.
                        </span>
                    </p> -->

                    <!-- <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, brandId: this.$route.params.brandId } }">Access control</router-link>
                    </p> -->
                    <!-- <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                    <p class="mb-0 pb-0">
                    </p> -->
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Brand Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.brand, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editBrandNameDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the brand label</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The brand label informs users about the content of communications they will receive if they subscribe to it.
                            You can change it at any time, but the new meaning should be similar to the old one. It IS shown to users.
                        </p>

                        <v-text-field
                            ref="brandNameInput"
                            v-model="editableBrandName"
                            label="Name"
                            :rules="newBrandNameRules"
                            validate-on-blur
                            color="green"
                            required
                            type="text"
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditBrandName" :disabled="!isEditBrandNameComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editBrandNameDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editBrandAliasDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the brand alias</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The brand alias is the way other websites link to your published BrandProfile.
                            You can change it at any time, and we will automatically redirect requests from your old alias to your new alias.
                        </p>
                        <p>
                            You can create one free brand alias. A free brand alias starts with text you choose, such as your brand name,
                            followed by some random characters so that multiple unrelated brands can use their own name without a conflict.
                        </p>
                        <p>
                            You can also create a premium brand alias, which contains only text you choose, such as your brand name,
                            without any random characters. These are generally assigned on a first-come, first-served basis.
                        </p>
                        <p>
                            If you are trying to create a premium brand alias, but it's already taken by someone else, try adding
                            a distinguishing word to the alias.
                            <!-- If you have a trademark to the brand name, and if you believe the
                            person or organization that reserved the same alias isn't selling a product or service in a different
                            industry under their own trademark, you can contact customer support to start a dispute process. -->
                            <!-- However, if you reserve a brand alias that is a trademarked name, or closely associated with a trademarked name,
                            and you don't actually sell any product or service with your own similar trademark, a legitimate owner of
                            the trademark may initiate a dispute. -->
                        </p>

                        <!-- <v-text-field
                            ref="brandFreeAliasInput"
                            v-model="editableBrandFreeAlias"
                            label="Alias"
                            :rules="newBrandAliasRules"
                            validate-on-blur
                            color="green"
                            required
                            type="text"
                            outlined
                            dense
                        >
                        </v-text-field>

                        <p v-if="editableBrandFreeAlias">
                            Your full alias will look like this: <span class="text-bold">{{ brandFreeAliasDisplay }}</span>
                        </p> -->
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn elevation="4" class="green white--text" @click="saveEditBrandFreeAlias" :disabled="!isEditBrandFreeAliasComplete">
                        <span>Save</span>
                    </v-btn> -->
                    <v-btn text color="grey" @click="editBrandAliasDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editBrandFreeAliasDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the brand free alias</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The brand alias is the way other websites link to your published BrandProfile. When you publish your BrandProfile the alias becomes public too.
                            You can change it at any time, but changing it after you've published your BrandProfile might break existing links which could then affect your reputation and sales. If you need to rename your alias without breaking those existing links, contact customer support and we can help.
                        </p>

                        <v-text-field
                            ref="brandFreeAliasInput"
                            v-model="editableBrandFreeAlias"
                            label="Alias"
                            :rules="newBrandAliasRules"
                            validate-on-blur
                            color="green"
                            required
                            type="text"
                            outlined
                            dense
                        >
                        </v-text-field>

                        <p v-if="editableBrandFreeAlias">
                            Your full alias will look like this: <span class="text-bold">{{ brandFreeAliasDisplay }}</span>
                        </p>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditBrandFreeAlias" :disabled="!isEditBrandFreeAliasComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editBrandFreeAliasDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editBrandPremiumAliasDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the brand premium alias</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The brand alias is the way other websites link to your published BrandProfile. When you publish your BrandProfile the alias becomes public too.
                        </p>
                        <p>
                            If you don't want the extra random characters to appear at the end of the alias, create a premium alias.
                        </p>
                        <p>
                            You can change it at any time, but changing it after you've published your BrandProfile might break existing links which could then affect your reputation and sales. If you need to rename your alias without breaking those existing links, contact customer support and we can help.
                        </p>

                        <v-text-field
                            ref="brandPremiumAliasInput"
                            v-model="editableBrandPremiumAlias"
                            label="Alias"
                            :rules="newBrandAliasRules"
                            validate-on-blur
                            color="green"
                            required
                            type="text"
                            outlined
                            dense
                        >
                        </v-text-field>

                        <p>
                            Your premium alias will appear exactly as you entered.
                        </p>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditBrandPremiumAlias" :disabled="!isEditBrandPremiumAliasComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editBrandPremiumAliasDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editBrandCommentDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the brand comment</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The brand comment is a place where you can add more information for your own reference about how you use the brand.
                            You can change it at any time. It is NOT shown to users.
                        </p>
                        <v-textarea v-model="editableBrandComment" label="Brand comment"></v-textarea>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditBrandComment" :disabled="!isEditBrandCommentComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editBrandCommentDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editBrandUnsubscribeDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the unsubscribe setting</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            Show respect for your audience by providing an easy-to-find unsubscribe link.
                        </p>

                        <v-checkbox
                            v-model="editableBrandUnsubscribeLinkGenerator"
                            label="Use Emplus for email audience management"
                            color="green"
                            dense
                            true-value="emplus"
                            false-value=""
                        >
                        </v-checkbox>

                        <v-text-field
                            ref="brandUnsubscribeLinkInput"
                            v-model="editableBrandUnsubscribeLink"
                            label="Unsubscribe URL"
                            :rules="newBrandUnsubscribeLinkRules"
                            validate-on-blur
                            color="green"
                            required
                            type="text"
                            outlined
                            dense
                            :disabled="!!editableBrandUnsubscribeLinkGenerator"
                        >
                        </v-text-field>

                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditBrandUnsubscribe" :disabled="!isEditBrandUnsubscribeComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editBrandUnsubscribeDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import BrandBar from '@/components/account-dashboard/BrandBar.vue';
// import BrandList from '@/components/account-dashboard/BrandList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        // BrandList,
        AccessDeniedOverlay,
        BrandBar,
    },
    data: () => ({
        brandAliasList: [],

        error: null,
        forbiddenError: null,
        account: null,
        brand: null,
        invite: null,
        // edit form title dialog
        editBrandNameDialog: false,
        editBrandAliasDialog: false,
        editBrandFreeAliasDialog: false,
        editBrandPremiumAliasDialog: false,
        editBrandCommentDialog: false,
        editBrandUnsubscribeDialog: false,

        editableBrandComment: null,
        editableBrandName: null,
        editableBrandFreeAlias: null,
        editableBrandPremiumAlias: null,
        editableBrandUnsubscribeLink: null,
        editableBrandUnsubscribeLinkGenerator: null,

        newBrandUnsubscribeLinkRules: [
            // TODO: is valid URL
        ],

        isDraft: null, // draft means there are unpublished changes
        isPublished: null, // published means it's been published (but there could still be unpublished changes pending)
        brandFreeAliasRandom: 'XXXX', // TODO: generate this or ask server to generate it (or we just get it after)
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.brand !== null;
        },
        isEditBrandNameComplete() {
            return typeof this.editableBrandName === 'string' && this.editableBrandName.trim().length > 0;
        },
        isEditBrandFreeAliasComplete() {
            return typeof this.editableBrandFreeAlias === 'string' && this.editableBrandFreeAlias.trim().length > 0;
        },
        isEditBrandPremiumAliasComplete() {
            return typeof this.editableBrandPremiumAlias === 'string' && this.editableBrandPremiumAlias.trim().length > 0;
        },
        isEditBrandCommentComplete() {
            return typeof this.editableBrandComment === 'string' && this.editableBrandComment.trim().length > 0;
        },
        isEditBrandUnsubscribeComplete() {
            return this.editableBrandUnsubscribeLinkGenerator || this.editableBrandUnsubscribeLink;
        },
        brandFreeAliasDisplay() {
            return `${this.editableBrandFreeAlias ?? ''}-${this.brandFreeAliasRandom}`;
        },
        linkViewBrandAlias() {
            const cdn = process.env.VUE_APP_CDN_WEBSITE_PROFILE_URL ?? 'https://cdn.brandprofile.org/view';
            if (this.brand?.alias) {
                return `${cdn}/${encodeURIComponent(this.brand.alias)}`;
            }
            return '';
        },
        linkViewBrandFreeAlias() {
            const cdn = process.env.VUE_APP_CDN_WEBSITE_PROFILE_URL ?? 'https://cdn.brandprofile.org/view';
            if (this.brand?.free_alias) {
                return `${cdn}/${encodeURIComponent(this.brand.free_alias)}`;
            }
            return '';
        },
        linkViewBrandPremiumAlias() {
            const cdn = process.env.VUE_APP_CDN_WEBSITE_PROFILE_URL ?? 'https://cdn.brandprofile.org/view';
            if (this.brand?.premium_alias) {
                return `${cdn}/${encodeURIComponent(this.brand.premium_alias)}`;
            }
            return '';
        },
        linkBrandUnsubscribe() {
            if (this.brand?.attr?.unsubscribe_link === 'emplus') {
                return this.brand.attr.unsubscribe_link_href;
            }
            return this.brand.attr.unsubscribe_link;
        },
    },
    watch: {
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadBrand() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadBrand: true });
                const response = await this.$client.account(this.$route.params.accountId).brand.get({ id: this.$route.params.brandId });
                console.log(`loadBrand: response ${JSON.stringify(response)}`);
                if (response) {
                    this.brand = response;
                    this.checkAlias();
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load brand');
                }
            } catch (err) {
                console.error('failed to load brand', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadBrand: false });
            }
        },
        async loadBrandAliasList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadBrandAliasList: true });
                const response = await this.$client.account(this.$route.params.accountId).brandAlias.search({ brand_id: this.$route.params.brandId });
                console.log(`loadBrandAliasList: response ${JSON.stringify(response)}`);
                if (response?.list) {
                    this.brandAliasList = response.list;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load brand alias list');
                }
            } catch (err) {
                console.error('failed to load brand alias list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadBrandAliasList: false });
            }
        },
        async save(brandAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditBrand: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).brand.edit({ id: this.$route.params.brandId }, brandAttr);
                console.log(`saveEditBrand: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit brand' });
                return false;
            } catch (err) {
                console.error('failed to edit brand', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit brand' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditBrand: false });
            }
        },
        async saveAttr({ key, value }) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditBrandAttr: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).brandAttr.edit({ brand_id: this.$route.params.brandId }, { attr_key: key, attr_value: value });
                console.log(`saveEditBrandAttr: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.isDraft = true;
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit brand attribute' });
                return false;
            } catch (err) {
                console.error('failed to edit brand', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit brand attribute' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditBrandAttr: false });
            }
        },
        editBrandName() {
            this.editBrandNameDialog = true;
            this.editableBrandName = this.brand.name;
        },
        editBrandAlias() {
            this.editBrandAliasDialog = true;
        },
        editBrandFreeAlias() {
            this.editBrandFreeAliasDialog = true;
            // to avoid confusion, remove the random characters from the end of the free_alias so the input box only has the user-editable prefix
            const lastSeparatorIndex = this.brand.free_alias?.lastIndexOf('-');
            if (typeof lastSeparatorIndex === 'number' && lastSeparatorIndex > 0) {
                this.editableBrandFreeAlias = this.brand.free_alias.slice(0, lastSeparatorIndex);
            } else {
                this.editableBrandFreeAlias = this.brand.free_alias;
            }
        },
        editBrandPremiumAlias() {
            this.editBrandPremiumAliasDialog = true;
            this.editableBrandPremiumAlias = this.brand.premium_alias;
        },
        editBrandComment() {
            this.editBrandCommentDialog = true;
            this.editableBrandComment = this.brand.comment;
        },
        editBrandUnsubscribe() {
            this.editBrandUnsubscribeDialog = true;
            if (['emplus'].includes(this.brand.attr.unsubscribe_link)) {
                this.editableBrandUnsubscribeLinkGenerator = this.brand.attr.unsubscribe_link;
                this.editableBrandUnsubscribeLink = this.brand.attr.unsubscribe_link_href; // actually a read-only value, the input should be disabled when generator attribute is set
            } else {
                this.editableBrandUnsubscribeLinkGenerator = null;
                this.editableBrandUnsubscribeLink = this.brand.attr.unsubscribe_link;
            }
        },
        async saveEditBrandName() {
            const isEdited = await this.save({ name: this.editableBrandName });
            this.editBrandNameDialog = false;
            if (isEdited) {
                this.$set(this.brand, 'name', this.editableBrandName);
            }
        },
        async saveEditBrandFreeAlias() {
            const isEdited = await this.save({ free_alias: this.editableBrandFreeAlias });
            this.editBrandFreeAliasDialog = false;
            if (isEdited) {
                // this.$set(this.brand, 'free_alias', this.editableBrandFreeAlias); // we can't just do this because the server adds a suffix... we need to reload the brand info
                this.loadBrand();
            }
        },
        async saveEditBrandPremiumAlias() {
            const isEdited = await this.save({ premium_alias: this.editableBrandPremiumAlias });
            this.editBrandPremiumAliasDialog = false;
            if (isEdited) {
                this.$set(this.brand, 'premium_alias', this.editableBrandPremiumAlias);
            }
        },
        async saveEditBrandComment() {
            const isEdited = await this.save({ comment: this.editableBrandComment });
            this.editBrandCommentDialog = false;
            if (isEdited) {
                this.$set(this.brand, 'comment', this.editableBrandComment);
            }
        },
        async saveEditBrandUnsubscribe() {
            let value;
            if (this.editableBrandUnsubscribeLinkGenerator === 'emplus') {
                value = this.editableBrandUnsubscribeLinkGenerator;
            } else {
                value = this.editableBrandUnsubscribeLink;
            }
            const isEdited = await this.saveAttr({ key: 'unsubscribe_link', value });
            this.editBrandUnsubscribeDialog = false;
            if (isEdited) {
                if (this.editableBrandUnsubscribeLinkGenerator === 'emplus') {
                    // if the unsubscribe link is a special value such as 'emplus', the server will generate the full link automatically so reload the brand info to get the full link
                    this.loadBrand();
                } else {
                    this.$set(this.brand.attr, 'unsubscribe_link', this.editableBrandUnsubscribeLink);
                }
            }
        },
        async checkAlias() {
            try {
                this.error = false;
                this.$store.commit('loading', { checkAlias: true });
                const response = await this.$client.account(this.$route.params.accountId).brand.check({ id: this.$route.params.brandId, item: 'alias' });
                console.log(`checkAlias: response ${JSON.stringify(response)}`);
                // this.isPublished = typeof response?.isPublished === 'boolean' ? response.isPublished : null;
                if (response?.alias !== this.brand?.alias) {
                    this.$set(this.brand, 'alias', response.alias);
                }
            } catch (err) {
                console.error('failed to load brand alias status', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { checkAlias: false });
            }
        },
        async checkIsPublished() {
            try {
                this.error = false;
                this.$store.commit('loading', { checkIsPublished: true });
                const response = await this.$client.account(this.$route.params.accountId).brand.check({ id: this.$route.params.brandId, item: 'published' });
                console.log(`checkIsPublished: response ${JSON.stringify(response)}`);
                this.isPublished = typeof response?.isPublished === 'boolean' ? response.isPublished : null;
            } catch (err) {
                console.error('failed to load brand published status', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { checkIsPublished: false });
            }
        },
        async checkIsDraft() {
            try {
                this.error = false;
                this.$store.commit('loading', { checkIsDraft: true });
                const response = await this.$client.account(this.$route.params.accountId).brand.check({ id: this.$route.params.brandId, item: 'draft' });
                console.log(`checkIsDraft: response ${JSON.stringify(response)}`);
                this.isDraft = typeof response?.isDraft === 'boolean' ? response.isDraft : null;
            } catch (err) {
                console.error('failed to load brand published status', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { checkIsDraft: false });
            }
        },
        async publish() {
            try {
                this.error = false;
                this.$store.commit('loading', { publish: true });
                const response = await this.$client.account(this.$route.params.accountId).brand.publish({ id: this.$route.params.brandId });
                console.log(`publish: response ${JSON.stringify(response)}`);
                if (response?.isPublished) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.isDraft = false;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish brand info' });
                }
            } catch (err) {
                console.error('failed to publish brand info', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish brand info' });
            } finally {
                this.$store.commit('loading', { publish: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadBrand();
        this.loadBrandAliasList();
        this.checkIsPublished();
        this.checkIsDraft();
    },
};
</script>
